import api from '../api'
/**
 * 标签接收列表
 */
export class AcceptListApi extends api {
    acceptLabel(data){
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/acceptLabel`,
            method: 'post',
            data
        })
    }
    rejectAccept(data){
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/rejectAccept`,
            method: 'post',
            data
          })
    }
    getOrderByAccountId() {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/getOrderByAccountId`,
            method: 'post',
        })
    }
    exportLabel(orderId) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/exportLabel/` + orderId,
            method: 'get',
            responseType: 'blob'
        })
    }
}

export default new AcceptListApi('acceptList')
