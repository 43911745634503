<template>
    <list-condition-template ref="table" :rows="rows" :total="total"
     @page-info-change="handlePageInfoChange" :defaultSort="{prop:'updateTime',order:'descending'}"
       v-model:listQuery="listQuery" :batch-column="true" @selected="selectedLabels"><!--:index-column="true"  -->
      <template #condition>
        <el-form-item>
          <div class="demo-date-picker">
            <div class="block">
              <span class="demonstration">筛选日期：</span>
              <el-date-picker
                clearable
                value-format="YYYY-MM-DD"
                v-model="valueDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间">
              </el-date-picker>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-select @change="handleFilter" clearable style="margin-left: 10px; width: 150px" class="filter-item" placeholder="接收状态" v-model="listQuery.condition.acceptState">
            <el-option v-for="item in statusOptions" :key="item.name" :label="item.type" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input @keyup.enter="handleFilter" placeholder="物料代码/型号/制造商/订单批次  查询"  style="width: 250px;" v-model="listQuery.condition.keyword" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
        </el-form-item>
        <el-row>
          <el-button class="filter-item" type="success" size="mini" v-waves @click="batchAccept">批量接收</el-button>
          <el-button class="filter-item" type="success" size="mini" v-waves @click="batchExport">标签导出</el-button>
        </el-row>
      </template>
      <template #columns >
        <el-table-column width="110px" label="接收状态" align="center">
          <template #default="scope">
            <el-tag :type="showFilter(scope.row)">{{scope.row.acceptState?scope.row.acceptState.type:''}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column min-width="100px" label="订单批次" property="orderBatch" align="center">
          <template #default="scope">
            <span>{{scope.row.order.orderBatch}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" label="发货时间" property="distributeTime" align="center">
          <template  #default="scope">
            <span>{{scope.row.distributeTime}}</span>
          </template>
        </el-table-column>
        <el-table-column label="物料代码" align="center" property="materialCode">
          <template  #default="scope">
            <span>{{scope.row.model.materialCode}}</span>
          </template>
        </el-table-column>
        <el-table-column label="型号" min-width="200px" align="center" property="name">
          <template  #default="scope">
            <span>{{scope.row.model.name}}</span>
          </template>
        </el-table-column>
        <el-table-column width="80px" label="防伪码标签（套）" property="labelCount" align="center">
          <template #default="scope">
            <span>{{scope.row.labelCount}}</span>
          </template>
        </el-table-column>
        <el-table-column width="80px" label="条形码标签（枚）"  property="barcodeNumber" align="center">
          <template #default="scope">
            <span>{{scope.row.barcodeCount?scope.row.barcodeCount:0}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" label="制造商" property="manufacturer.name" align="center">
          <template #default="scope">
            <span>{{scope.row.manufacturer.name}}</span>
          </template>
        </el-table-column>
         <el-table-column min-width="150px" label="物流单号" property="logisticsNumber" align="center">
          <template #default="scope">
            <span>{{scope.row.logisticsNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column width="80px" label="接收人" property="consignee" align="center">
          <template #default="scope">
            <span>{{scope.row.consignee?scope.row.consignee:''}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" label="接收时间" property="acceptTime" align="center">
          <template  #default="scope">
            <span>{{scope.row.acceptTime}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200px" class-name="fixed-width" fixed="right">
          <template  #default="scope">
            <el-button v-if="scope.row.acceptState&&scope.row.acceptState.name==='IN_ACCEPT'" size="mini" type="primary" @click="handleAccept(scope.row)" v-waves>确认收货</el-button>
            <el-button v-if="scope.row.acceptState&&scope.row.acceptState.name==='IN_ACCEPT'" size="mini" type="danger" @click="rejectAccept(scope.row)" v-waves>拒收</el-button>
          </template>
        </el-table-column>
    </template>
    <el-dialog v-model="acceptShow" :title="acceptTitle" center width="450px" @close="onCancelButtonClick(false)">
        <el-form ref="acceptInfo" :model="acceptInfo" label-width="100px" :rules="consigneeRules">
            <el-form-item :label="consigneeTitle" prop="consignee" >
                <el-input v-model.trim="acceptInfo.consignee" maxlength="10" show-word-limit :placeholder="acceptPlaceholder"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button size="mini" @click="onCancelButtonClick">取消</el-button>
                <el-button size="mini" type="primary" @click="onSubmitButtonClick(false)" :loading="acceptInfo.loading">{{acceptInfo.loading ? '提交中' : '确定'}}</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>

    <el-dialog v-model="mixShow" :title="mixTitle" center width="450px" @close="onCancelButtonClick(false)">
      <el-form ref="mixInfo" label-width="100px">
        <el-form-item label="订单批次" required>
          <el-select placeholder="请选择" value-key="id" filterable v-model="orderInfo">
            <el-option v-for="(item,index) in orderBatchOptions" :key="index" :label="item.orderBatch" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" @click="onCancelButtonClick">取消</el-button>
          <el-button size="mini" type="primary" @click="onSubmitMix('mixInfo')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </list-condition-template>
</template>

<script>
import waves from '@/directive/waves' // 水波纹指令
import listConditionTemplate from '@/components/templates/listConditionTemplate'
import acceptListApi from '@/apis/accept/acceptList'
import { saveAs } from 'file-saver'
export default {
  directives: {
    waves
  },
  components: {
    listConditionTemplate
  },
  data () {
    return {
      selectedLabelList: undefined,
      isBatchAccept: false,
      statusOptions: [
        {
          name: 'IN_ACCEPT',
          type: '待收货'
        },
        {
          name: 'REJECT_ACCEPT',
          type: '拒收'
        },
        {
          name: 'FINISH_ACCEPT',
          type: '已收货'
        }
      ],
      acceptShow: false,
      mixShow: false,
      mixTitle: '批量导出',
      orderBatchOptions: [],
      orderInfo: '',
      acceptTitle: '确认收货',
      consigneeTitle: '接收人:',
      acceptPlaceholder: '请填写接收人（必填）',
      acceptInfo: {
        loading: false,
        id: null,
        consignee: null
      },
      valueDate: undefined,
      rows: null,
      total: 0,
      listQuery: {
        condition:{
          keyword: null,
          startTime: undefined,
          endTime: undefined,
          acceptState: undefined
        },
        pageInfo:{
          pageIndex: 1,
          pageSize: 20,
          orders: []
        }
      },
      consigneeRules: {
        consignee: [
          { required: true, message: "接收人不能为空", trigger: "blur" },
          {
            validator: function(rule, value, callback) {
              if (/^[\u4e00-\u9fa5]+$/.test(value) == false) {
                callback(new Error("接收人只能输入中文"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur"
          }
        ],
}

    }
  },
  filters: {
  },
  watch: {
    acceptShow: {
      handler (newAcceptShow, oldAcceptShow) {
        if(!newAcceptShow){
          this.selectedLabelList = undefined
          this.isBatchAccept = false
          this.$refs.table.clearSelection()
        }
      }
    }
  },
  methods: {
    rejectAccept(row){
      this.$confirm('拒收时同一订单批次，同一厂家下所有标签都将拒收，确认拒收吗？', '警告', {
          type: 'warning'
      }).then(() => {
        this.acceptShow = true
        this.acceptTitle = '确认拒收'
        this.consigneeTitle = '拒收人:'
        this.acceptPlaceholder = '请填写拒收人（必填）'
        this.acceptInfo = JSON.parse(JSON.stringify(row))
      })
    },
    selectedLabels(data){
      this.selectedLabelList = data
    },
    batchAccept(){
      if(!this.selectedLabelList || this.selectedLabelList.length<1){
        this.$message.warning("请勾选数据")
      }else{
        this.acceptShow = true
        this.isBatchAccept = true
      }
    },
    batchExport() {
      this.mixShow = true
      acceptListApi.getOrderByAccountId().then(res => {
        this.orderBatchOptions = res.data
      }).catch(res => {
        this.mixShow = false
      })
    },
    onSubmitMix(formName) {
      const orderId = this.orderInfo.id
      const orderBatch = this.orderInfo.orderBatch
      if (!orderId) {
        this.$message.warning("请选择订单批次")
        return false
      }
      
      this.mixShow = false
      acceptListApi.exportLabel(orderId).then(res => {
        const excelName = '订单批次' + orderBatch + '的标签接收列表.xlsx'
        saveAs(res.data,excelName)
      }).catch(res => {
        this.$message.error("无法下载")
      })
    },
    showFilter(row){
      if(row.acceptState && row.acceptState.name === 'FINISH_ACCEPT'){
        return 'success'
      }else if(row.acceptState && row.acceptState.name === 'IN_ACCEPT'){
        return 'warning'
      }else{
        return 'danger'
      }
    },
    handleFilter () {
      if(this.valueDate){
        this.listQuery.condition.startTime = this.valueDate[0] + " 00:00:00"
        this.listQuery.condition.endTime = this.valueDate[1] + " 23:59:59"
      }else{
        this.listQuery.condition.startTime = undefined
        this.listQuery.condition.endTime = undefined
      }
      this.getList()
    },
    handlePageInfoChange () {
      this.getList()
    },
    getList () {
      acceptListApi
        .getPaged(this.listQuery)
        .then(resp => {
          //this.rows = resp.data.content
          if(resp.data.content){
            this.rows = resp.data.content.map(labelInfo=>{
              if(labelInfo.model.page > 1){
                labelInfo.barcodeCount = labelInfo.labelCount * labelInfo.model.page
              }else{
                labelInfo.barcodeCount = 0
              }
              if(labelInfo.consignee||labelInfo.acceptState.name==='REJECT_ACCEPT'){
                labelInfo.isDisabled = true
              }else{
                labelInfo.isDisabled = false
              }
              return labelInfo
            })
          }
          this.total = resp.data.totalElements
        })
    },
    handleAccept(row){
      this.acceptShow = true
      this.acceptTitle = '确认收货'
      this.consigneeTitle = '接收人:'
      this.acceptPlaceholder = '请填写接收人（必填）'
      this.acceptInfo = JSON.parse(JSON.stringify(row))
    },
    onSubmitButtonClick() {
      if (this.acceptTitle === '确认收货') {
        this.onSubmitButtonAccept()
      }
      if (this.acceptTitle === '确认拒收') {
        this.onSubmitButtonNoAccept(this.acceptInfo)
      }
    },
    onSubmitButtonNoAccept(row) {
      this.$refs.acceptInfo.validate().then(valid => {
        if (valid) {
          this.acceptInfo.loading = true
          acceptListApi.rejectAccept(row).then(resp=>{
            if(resp.data){
              this.acceptShow = false
              this.acceptInfo.loading = false
              this.getList()
            }
          })
        }
      })
    },
    onSubmitButtonAccept(){
      this.$refs.acceptInfo.validate().then(response => {
        this.$confirm('同一订单批次，同一厂家下所有标签都将一起收货，确认收货吗？', '提醒', {
            type: 'warning'
        }).then(() => {
          this.acceptInfo.loading = true
          if(this.isBatchAccept){
            this.selectedLabelList.forEach(element => {
              var info = JSON.parse(JSON.stringify(element))
              info.consignee = this.acceptInfo.consignee
              acceptListApi.acceptLabel(info).then(resp=>{
              })
            });
            setTimeout(() => {
              this.$message.success("成功")
              this.acceptShow = false
              this.isBatchAccept = false
              this.acceptInfo = {
                loading: false,
                id: null,
                consignee: null
              }
              this.getList()
            }, 300)
          }else{
            acceptListApi.acceptLabel(this.acceptInfo).then(resp=>{
              if(resp.data){
                this.$message.success("成功")
                this.acceptShow = false
                this.acceptInfo = {
                  loading: false,
                  id: null,
                  consignee: null
                }
                this.getList()
              }
            })
          }
        })

      })
    },
    onCancelButtonClick(){
      this.acceptShow = false
      this.orderInfo = ''
      this.mixShow = false
    },
  }
}
</script>
